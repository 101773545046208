import React, { useEffect, useRef, useState } from "react";
//= Scripts
import navbarScrollEffect from "common/navbarScrollEffect";
//= Layout
import MainLayout from "layouts/Main";
//= Components
import Navbar from "components/Navbars/ITCreativeNav";
import Footer from "components/Saas/Footer";
import axios from "axios";

const Profile = ({ params }) => {
  const navbarRef = useRef(null);
  //const data =  news;
  const [data, setData] = useState({});

  const [loading, setLoading] = useState(false);

  const { id } = params;

  useEffect(() => {
    navbarScrollEffect(navbarRef.current, true);
  }, [navbarRef]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await axios
        .get(`https://blog.mutualtrustmfb.com/director/${id}`)
        .then((response) => {
          const res = setData(response.data);
          console.log(res);
        });
      setLoading(false);
    })();
  }, []);



  return (
    <MainLayout>
      <Navbar navbarRef={navbarRef} />
      <main className="product-page style-5">
        <section className="product pt-50">
          <div className="container">
          
            <div className="content">
            
              <div className="row gx-5">
              
                <div className="col-lg-4">
                <div className="img">
                  <img src={data.image} alt="" />
                </div>
                </div>
                <div className="col-lg-8">
                  <div className="product-info">
                    <>
                    
                      <h2 className="title">{data.name}</h2>
                      <h5 className="p-2">{data.position}</h5>
                    
                      <div className="info-text">{data.Details}</div>
                      
                    </>

                    {/* <MoreInfo moreInfo={data.moreInfo} rtl={rtl} />
                <SocialIcons /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
      </main>
      <Footer noWave />
    </MainLayout>
  );
};

export const Head = () => {
  return (
    <>
      <title>Profile</title>
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  );
};

export default Profile;
